<template>
  <div class="page-about-us">
    <CommonBanner></CommonBanner>
    <div class="about-detail ">
      <h4 class="title">关于恒链：</h4>
      <div class="text-intro">
        上海市恒链科技有限公司（简称：上海恒链）成立于2019年，是国内领先的可视化物流SAAS提供商。公司融合运营人工智能AI、大数据、云计算、IPA全流程自动化等技术，研发完成海运物流变更性产品，在无纸化办公领域有丰富的项目经验，利用数字化和自动化帮助中小型物流供应商解决人力资源成本问题，提高企业办公效率。
      </div>
      <h4 class="title">联系方式：</h4>
      <div class="contact-ways">
        <div class="contact-item">
          <span class="label">地址：</span>
          <span class="value address">
             <span>上海市泸宜公路1188号20号301室</span>
             <span>深圳市罗湖区金丰城大厦B座1201</span>
          </span>
        </div>
         <div class="contact-item">
          <span class="label">电话：</span>
          <span class="value">021-69956067、15361416835</span>
        </div>
         <div class="contact-item">
          <span class="label">邮箱：</span>
          <span class="value">jane@sfyf.cn</span>
        </div>
         <div class="contact-item">
          <span class="label">微信：</span>
          <span class="value">哒哒订舱</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../style/mixin.scss";
  .page-about-us {
     margin-bottom: 48px;
    .about-detail {
      background: #FFF8F3;
      border-radius: 8px;
      padding: 30px 24px;
      .title {
         font-size: 24px;
         font-weight: 600;
         margin-bottom: 20px;
         color: $themeColor;
        //  color: #5e2905;
      }
      .text-intro {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 40px;
        color: #5e2905;
      }
      .contact-item {
        display: flex;
        margin-bottom: 20px;
        color: #5e2905;
        .label {
          margin-right: 10px;
          min-width: 40px;
          white-space: nowrap;
        }
        .value {
          margin-right: 10px;
          &.address {
            span {
             display: block;
            }
          }
        }
      }
    }
  }
</style>

<script>
import CommonBanner from "../../components/CommonBanner";

export default {
  components: {
    CommonBanner
  }
}
</script>
